
import { queryOpenStatusApi } from '../../request/api/login'

export default {
    //命名空间
    namespace: "bussimessTicket",
    //仓库初始化状态
    state: {
        OpenStatustList: {}
    },
    reducers: {
        updateReducers(state, { type, payload }) {
            return {
                ...state,
                [payload.key]: payload.val
            }
        }
    },
    effects: {
        //页面接口
        *getOpenStatustListData(action, { call, put }) {
            const result = yield call(queryOpenStatusApi, action.payload)
            yield put({ type: 'updateReducers', payload: { key: 'OpenStatustList', val: result.data } })
        }
    }
};
