import { getStorage } from '@/utils/tools';

export default {
    //命名空间
    namespace: "login",
    //仓库初始化状态
    state: {
        tokens: getStorage('tokens') ? getStorage('tokens') : ''
    },
    reducers: {
        setToken(state, { type, payload }) {
            return {
                ...state,
                tokens: payload
            }
        }
    }
};
