export default {
    //命名空间
    namespace: "clientInfoManage",
    //仓库初始化状态
    state: {
      current:0,
    },
    reducers: {
        // updateProdutList(state, { type, payload }) {
        //     return {
        //         ...state,
        //         productList: payload
        //     }
        // }
    },
    effects: {
        // *getProductList(action, { call, put }) {
        //     const result = yield call(接口方法)
        //     yield put({ type: 'updateProdutList', payload: result.data.currentUser })
        // }
    },
};
