
import scfBaseApi from '@/request/api/scfBaseApi';
import scfBusinessApi from '@/request/api/scfBusinessApi';
const findDicLtApi = scfBusinessApi['findDicLtApi'];
const queryCapitalApi = scfBaseApi['queryCapitalApi'];
const queryCoreOrgApi = scfBaseApi['queryCoreOrgApi'];
const getenterpriseCreditListApi = scfBusinessApi['getenterpriseCreditListApi'];
const getEnterpriseCreditDetailApi = scfBusinessApi['getEnterpriseCreditDetailApi'];

export default {
    //命名空间
    namespace: "coreBusinessCredit",
    //仓库初始化状态
    state: {
        detailData: {},
        capitalList: [],
        EnterpriseList: [],
        companyStateList: [],
        CoreBusinessCreditList: [],
    },
    reducers: {
        updateReducers(state, { type, payload }) {
            return {
                ...state,
                [payload.key]: payload.val
            }
        }
    },
    effects: {
        //页面接口
        *getenterpriseCreditListData(action, { call, put }) {
            const result = yield call(getenterpriseCreditListApi, action.payload)
            yield put({ type: 'updateReducers', payload: { key: 'CoreBusinessCreditList', val: result.data } })
        },
        *getAllcapitalData(action, { call, put }) {
            const result = yield call(queryCapitalApi, action.payload)
            yield put({ type: 'updateReducers', payload: { key: 'capitalList', val: result.data } })
        },
        *getAllEnterpriseData(action, { call, put }) {
            const result = yield call(queryCoreOrgApi, action.payload)
            yield put({ type: 'updateReducers', payload: { key: 'EnterpriseList', val: result.data } })
        },
        *getEnterpriseCreditDetail(action, { call, put }) {
            const result = yield call(getEnterpriseCreditDetailApi, action.payload)
            yield put({ type: 'updateReducers', payload: { key: 'detailData', val: result.data } })
        },
        *getAllCompanyStateData(action, { call, put }) {
            const params = { dicType: action.payload };
            const result = yield call(findDicLtApi, params)
            yield put({ type: 'updateReducers', payload: { key: 'companyStateList', val: result.data } })
        }
    },
};
