import React from 'react';
import RouterMap from '@/router/map';
import Routes from '@/router/routes';
import moment from 'moment';
import 'moment/locale/zh-cn'
import {ConfigProvider} from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';

moment.locale('zh-cn');

//dva的Routerview 只能是一个函数
function RouterView(props) {
    const routes = props.routes ? props.routes : Routes;
    return <div style={{height:"100%"}}>
        <ConfigProvider locale={zh_CN}>
            <RouterMap routes={routes} {...props}></RouterMap>
        </ConfigProvider>
    </div>
}

export default RouterView;